import React from 'react'
import Image from 'photon/src/components/Image'
import PlaceholderImage from 'photon/src/components/PlaceholderImage'
import { withTheme } from 'styled-components';
import {useSite} from 'wpapi/hooks/useSite'

import mobileLogo from 'app/theme/assets/logo/photon-logo-mobile-v2.png'

//styles
import {
  WrapLogo,
} from './styles'

function SiteLogo({link, inline, theme}) {
  const site = useSite()

  const logo = (site && site.logo) || '';

  if (link) {
    return (
      <a href={'/'}>
        <PlaceholderImage
          src={logo}
          w={`${theme.wpLogo.width}px`}
          h={`${theme.wpLogo.height}px`}
        >
          <WrapLogo>
            <Image className="desktop" src={logo} />
            <Image className="mobile" src={mobileLogo} />
          </WrapLogo>
        </PlaceholderImage>
      </a>
    );
  }

  return (
    <PlaceholderImage
      src={logo}
      w={`${theme.wpLogo.width}px`}
      h={`${theme.wpLogo.height}px`}
    >
      <Image {...({src: logo, ...theme.wpLogo})} />
    </PlaceholderImage>
  );
}

export default withTheme(SiteLogo);
