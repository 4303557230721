export const firebaseConfig = {
  apiKey: "AIzaSyDR67Kmol7QU0fOsNfich_GA32EPYZASJw",
  authDomain: "photon-software-4ef7b.firebaseapp.com",
  databaseURL: "https://photon-software-4ef7b.firebaseio.com",
  projectId: "photon-software-4ef7b",
  storageBucket: "photon-software-4ef7b.appspot.com",
  messagingSenderId: "17878580864",
  appId: "1:17878580864:web:4c033b0ca8fb52961d3065",
  measurementId: "G-7Y4NEFV90W"
};
