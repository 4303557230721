import styled from 'styled-components'
import Box from 'photon/src/components/Box'
import Container from 'photon/src/components/Container'
import { respondTo } from 'photon'

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${props => props.theme.colors.white};

  .wp-menu ul li a.border {
    padding: 7px 24px 7px 25px;
    border-radius: 6px;
    border: solid 1px ${props => props.theme.colors.black};
  }
`

export const HeaderInner = styled(Container)`
  padding: .5em;
  justify-content: space-between;

  ${props => respondTo(props.theme.media[2])} {
    padding: 1.5em .5em;
  }
`

export const LogoWrapper = styled(Box)`

`
