import styled from 'styled-components'
import { respondTo } from 'photon'

export const WrapLogo =  styled.div`
  .desktop {
    display: none;
    max-width: 250px;
    height: auto;

    ${props => respondTo(props.theme.media[2])} {
      display: block;
    }
  }

  .mobile {
    display: block;
    max-width: 40px;
    height: auto;

    ${props => respondTo(props.theme.media[2])} {
      display: none;
    }
  }
`
