import styled, {css} from 'styled-components'
import {getTheme, mediaStyles} from 'photon'

const typographyStyles = getTheme('typography', {})

const headingStyles = props => css`
  color: ${props.color || `inherit`};

  ${props.size && (
    Array.isArray(props.size) ?
    mediaStyles('font-size', props.size) :
    `font-size: ${props.size};`
  )}

  ${props.mt && `margin-top: ${props.mt};`}
  ${props.ml && `margin-left: ${props.ml};`}
  ${props.mr && `margin-right: ${props.mr};`}
  ${props.mb && `margin-bottom: ${props.mb};`}
`;

export const H1Wrapper = styled.h1.attrs(props => ({
  className: `photon-h1`,
}))`
  ${css(typographyStyles.h1)}
  ${props => headingStyles(props)}
`

export const H2Wrapper = styled.h2.attrs(props => ({
  className: `photon-h2`,
}))`
  ${css(typographyStyles.h2)}
  ${props => headingStyles(props)}
`

export const H3Wrapper = styled.h3.attrs(props => ({
  className: `photon-h3`,
}))`
  ${css(typographyStyles.h3)}
  ${props => headingStyles(props)}
`

export const H4Wrapper = styled.h4.attrs(props => ({
  className: `photon-h4`,
}))`
  ${css(typographyStyles.h4)}
  ${props => headingStyles(props)}
`

export const H5Wrapper = styled.h5.attrs(props => ({
  className: `photon-h5`,
}))`
  ${css(typographyStyles.h5)}
  ${props => headingStyles(props)}
`

export const H6Wrapper = styled.h6.attrs(props => ({
  className: `photon-h6`,
}))`
  ${css(typographyStyles.h6)}
  ${props => headingStyles(props)}
`
