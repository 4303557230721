import loadingIcon from './assets/loading.svg'
import logoSrc from './assets/logo/logo.svg'
import variables from './variables'
import typography from './typography'
import forms from './forms'

export default {
  ...variables,
  contentWidths: [
    "100%", // mobile
    "95%", // tablet max
    "960px", // laptop max
    "1000px", // desktop max
    // "", // xl max
  ],
  loadingIcon: loadingIcon,
  logo: {
    src: logoSrc,
    widths: ['160px', '180px', '220px'],
    alt: 'Photon Software logo',
    className: 'app-logo',
    width: 140,
    height: 49,
  },
  wpLogo: {
    widths: ['160px', '180px', '220px'],
    alt: 'Photon Software logo',
    className: 'app-logo',
    width: 140,
    height: 49,
  },
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#d9dff1',
    opacity: 0.6,
  },
  //
  typography,
  // Elements
  el: {
    ...forms
  },
  wp: {
    modules: {},
    templates: {},
  },
}
