import React from 'react';
import Link from 'photon/src/components/Link'
import {useMenu} from 'wpapi/hooks/useMenu'
import {
  MenuWrapper,
  MenuItem,
  ItemsWrapper,
} from './styles'

function Menu({location, stacked}) {
  location = location || 'primary'
  const menu = useMenu(location)

  return (
    <MenuWrapper className={`wp-menu location-${location}`}>
      <ItemsWrapper stacked={!!stacked}>
        {menu && menu.map((item, idx) => (
          <MenuItem stacked={!!stacked} key={item.ID}>
            <Link
              rel={item.xfn}
              href={item.url}
              target={item.target}
              title={item.attr_title.replace(/[^a-z|A-Z|0-9| |-|.]/g, '') || item.title.replace(/[^a-z|A-Z|0-9| |-|.]/g, '')}
              className={item.classes.join(' ')}
            >
              {item.title}
            </Link>
          </MenuItem>
        ))}
      </ItemsWrapper>
    </MenuWrapper>
  );
}

export default Menu;
