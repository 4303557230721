/**
 * Header
 */
import React from 'react';
// import Logo from 'photon/src/components/Logo'
import SiteLogo from 'app/lib/components/SiteLogo'
import Drawer from 'photon/src/contexts/Drawer'
import RespondTo from 'photon/src/components/RespondTo'
import {
  HeaderWrapper,
  LogoWrapper,
  HeaderInner,
} from './styles'
import Menu from 'wpapi/lib/components/Menu'

function Header(props) {

  const drawerMenu = () => <Drawer><Menu stacked /></Drawer>

  return (
    <>
      <HeaderWrapper>
        <HeaderInner flex={1}>
          <LogoWrapper>
            <SiteLogo link />
          </LogoWrapper>
          <RespondTo
            mobile={drawerMenu}
            tablet={drawerMenu}
            desktop={() => <Menu />}
          />
        </HeaderInner>
      </HeaderWrapper>
    </>
  );
}

export default Header
