import React from 'react';
import HubspotForm from 'react-hubspot-form'
import Menu from 'wpapi/lib/components/Menu'
import SiteLogo from 'wpapi/lib/components/SiteLogo'
import Heading from 'photon/src/components/Heading'
import {useSite} from 'wpapi/hooks/useSite'
import {
  Copyright,
  FooterCol,
  Newsletter,
  FooterInner,
  FooterWrapper,
} from './styles'

function Footer() {

  const site = useSite()

  const {background_color, background_image} = (
    (site && site.options && site.options.footer) || {}
  )

  return (<FooterWrapper
      style={{
        backgroundColor: background_color,
        backgroundImage: `url(${(background_image && background_image.url) || ''})`,
      }}
    >
    <FooterInner flex={1}>
      <FooterCol widths={['100%', '30%']}>
        <SiteLogo link />
        <Newsletter>
          <HubspotForm
            portalId={"7720442"}
            formId={"ce093372-5ff5-45e9-986a-9426ccfdcf78"}
          />
        </Newsletter>
      </FooterCol>
      <FooterCol widths={['5em']} />
      <FooterCol flexBox={{flex: 1}}>
        <Heading as={'h5'}>Support</Heading>
        <Menu location={'footer_1'} stacked />
      </FooterCol>
      <FooterCol flexBox={{flex: 1}}>
        <Heading as={'h5'}>Our Products</Heading>
        <Menu location={'footer_2'} stacked />
      </FooterCol>
      <FooterCol flexBox={{flex: 1}}>
        <Heading as={'h5'}>About Photon</Heading>
        <Menu location={'footer_3'} stacked />
      </FooterCol>
    </FooterInner>
    <Copyright>
      &copy; Photon Software. All Rights Reserved.
    </Copyright>
  </FooterWrapper>);
}

export default Footer
