import {useEffect} from 'react';
import {initMessaging} from 'fb'

export default ({
  alarmIcon,
  ...props
}) => {

  // const [incoming, setIncoming] = useState(false)
  // const [message, setMessage] = useState(null)

  useEffect(() => {
    const handleNotifications = (payload) => {
      console.log('onMessage: ', {payload});
      // const {notification} = payload
      // setIncoming(true)
      // setMessage(notification)
    }

    initMessaging({handleNotifications})
  }, [])

  return null;
  // incoming && (
  //   <NotificationStyle>
  //     <Button
  //       variant="outlined"
  //       colors="secondary"
  //       onClick={__openModal}
  //       icon={<Image src={AlarmIcon} {...alarmIcon} />}
  //     />
  //   </NotificationStyle>
  // );
};
