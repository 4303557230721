import React from 'react';
import AppRouter from './router'
import Spectra from 'spectra'
import './styles/main.scss'
import Header from './parts/Header'
import Footer from './parts/Footer'
import GlobalStyles from './parts/GlobalStyles'
import {ThemeProvider} from 'styled-components'
import {firebaseConfig} from '../firebaseConfig'
import photonTheme from 'app/theme'
import Notification from 'photon/src/components/Notification'

function App() {

  return (
    <Spectra config={firebaseConfig}>
      <ThemeProvider theme={photonTheme}>
        <GlobalStyles />
        <Header />
        <AppRouter />
        <Footer />
        <Notification />
      </ThemeProvider>
    </Spectra>
  );
}

export default App;
